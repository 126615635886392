import CNInput from '@/components/ui/CNInput/CNInput'
import CNListItem from '@/components/ui/CNListItem/CNListItem'
import CDivider from '@/components/CDivider'
import ConditionModal from './components/ConditionModal'
import modalComponent from '@/mixin/modalComponent'
import Tag from './components/Tag'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'

export default {
    mixins: [modalComponent],
    inject: ['toast'],
    props: {
        form: {
            type: Object,
            default: {},
        },
    },
    components: {
        CNInput,
        CNListItem,
        CDivider,
        ConditionModal,
        Tag,
    },
    data() {
        return {
            validator: {},
            validationErrors: {},
            showConditionModal: false,
            selectedCondition: undefined,
            options: [],
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.fetchProfessions()
            this.setRules()
        })
    },
    methods: {
        fetchProfessions() {
            this.$http.setting
                .fetchProfessions()
                .then((res) => this.options = res.data.map((el) => ({value: el.id, name: el.title})))
        },
        validateField(f) {
            this.validationErrors[f] = this.validator.validate(f, this.form[f])
        },
        clearErrors() {
            this.validationErrors = {}
            this.clearErrorsRef()
        },
        setRules() {
            this.validator = validator({
                name: [rules.required, rules.strMax(255)],
            })
        },
        handleEditCondition(index, condition) {
            this.selectedCondition = this.$deepClone(condition)
            this.selectedCondition.index = index
            this.showConditionModal = true
        },
        handleSaveCondition(formData, key) {
            let actionType = 'created'
            formData.mandatory = true
            this.form[key].forEach((el, index) => {
                if (el.position_id === formData.position_id) formData.index = index
            })

            if (formData.index !== undefined) {
                actionType = 'updated'
                this.form[key][formData.index] = formData
            } else {
                this.form[key].unshift(formData)
            }

            this.showConditionModal = false

            this.toast('info', 'The condition has been successfully ' + actionType)
        },
        handleCancelCondition() {
            this.selectedCondition = undefined
            this.showConditionModal = false
        },
        conditionDelete(index, objKey) {
            let name = this.form[objKey][index]['condition_name']
            this.$modal.open({
                close: 'No, don’t delete',
                success: 'Yes, delete',
                sublabel: `Are you sure you want to delete сondition <strong>${name}</strong>?`,
                size: 'lg',
            })
                .then(() => {
                    this.$modal.close()
                    this.form[objKey].splice(index, 1)
                })
        },
    },
}
